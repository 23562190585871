<template>
    <div class="subscribe-main">
        <top-nav></top-nav>
        <header-guide></header-guide>
        <bread-crumb></bread-crumb>

        <div
            class="add-banner"
            :style="{backgroundImage:'url('+$img.top_add+')',height:'100px',backgroundSize:'cover',backgroundRepeat:'none',backgroundPosition:'center center'}"
        >
            <div class="container">
                <el-button
                    round
                    class="invt-btn"
                >立即邀请</el-button>
            </div>
        </div>

        <div class="articleBox">
            <div class="searchBox">
                <div class="article_search">
                    <el-button @click="search">搜索资源</el-button>
                    <el-input
                        type="text"
                        v-model="keyword"
                        placeholder="输入关键词"
                        @keyup.enter.down="search"
                    ></el-input>
                </div>
            </div>

            <el-tabs
                @tab-click="tabChange"
                v-model="activeName"
            >
                <el-tab-pane
                    label="全部"
                    name="one"
                >
                    <div class="container">
                        <div class="contarea-panel">
                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">分类：</dd>
                                <dd
                                    :class="{'active':cateIndex==0}"
                                    @click="toggle('cate',0)"
                                >全部</dd>
                                <dd
                                    :class="{'active':cateIndex==1}"
                                    @click="toggle('cate',1)"
                                >行业新闻</dd>
                                <dd
                                    :class="{'active':cateIndex==2}"
                                    @click="toggle('cate',2)"
                                >政策文件</dd>
                                <dd
                                    :class="{'active':cateIndex==3}"
                                    @click="toggle('cate',3)"
                                >收费标准</dd>
                                <dd
                                    :class="{'active':cateIndex==4}"
                                    @click="toggle('cate',4)"
                                >标准规范</dd>
                                <dd
                                    :class="{'active':cateIndex==5}"
                                    @click="toggle('cate',5)"
                                >其他资料</dd>
                            </dl>

                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">专业：</dd>
                                <dd
                                    :class="{'active':professionIndex==0}"
                                    @click="toggle('profession',0)"
                                >全部</dd>
                                <dd
                                    @click="toggle('profession',item.id)"
                                    v-for="(item,index) in cateList"
                                    :key="index"
                                    :class="{'active':professionIndex==item.id}"
                                >{{item.title}}</dd>
                            </dl>

                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">排序：</dd>
                                <dd
                                    :class="{'active':sortIndex==1}"
                                    @click="toggle('sort',1)"
                                >最新</dd>
                                <dd
                                    :class="{'active':sortIndex==2}"
                                    @click="toggle('sort',2)"
                                >最热</dd>
                            </dl>

                            <article-list
                                ref="articleComp1"
                                :cate="cate"
                                :profession="profession"
                                :sort="sort"
                                :type="type"
                                :keyword="keyword"
                                :hot="hot"
                            ></article-list>
                        </div>
                        <div class="sidebar-panel">
                            <sidebar-panel
                                :news="news"
                                :dataShare="dataShare"
                            ></sidebar-panel>
                        </div>
                    </div>
                </el-tab-pane>

                <el-tab-pane
                    label="平台分享"
                    name="two"
                >
                    <div class="container">
                        <div class="contarea-panel">
                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">分类：</dd>
                                <dd
                                    :class="{'active':cateIndex==0}"
                                    @click="toggle('cate',0)"
                                >全部</dd>
                                <dd
                                    :class="{'active':cateIndex==1}"
                                    @click="toggle('cate',1)"
                                >行业新闻</dd>
                                <dd
                                    :class="{'active':cateIndex==2}"
                                    @click="toggle('cate',2)"
                                >政策文件</dd>
                                <dd
                                    :class="{'active':cateIndex==3}"
                                    @click="toggle('cate',3)"
                                >收费标准</dd>
                                <dd
                                    :class="{'active':cateIndex==4}"
                                    @click="toggle('cate',4)"
                                >标准规范</dd>
                            </dl>

                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">专业：</dd>
                                <dd
                                    :class="{'active':professionIndex==0}"
                                    @click="toggle('profession',0)"
                                >全部</dd>
                                <dd
                                    @click="toggle('profession',item.id)"
                                    v-for="(item,index) in cateList"
                                    :key="index"
                                    :class="{'active':professionIndex==item.id}"
                                >{{item.title}}</dd>
                            </dl>

                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">排序：</dd>
                                <dd
                                    :class="{'active':sortIndex==1}"
                                    @click="toggle('sort',1)"
                                >最新</dd>
                                <dd
                                    :class="{'active':sortIndex==2}"
                                    @click="toggle('sort',2)"
                                >最热</dd>
                            </dl>

                            <article-list
                                ref="articleComp2"
                                :cate="cate"
                                :profession="profession"
                                :sort="sort"
                                :type="type"
                                :keyword="keyword"
                                :hot="hot"
                            ></article-list>
                        </div>
                        <div class="sidebar-panel">
                            <sidebar-panel
                                :news="news"
                                :dataShare="dataShare"
                            ></sidebar-panel>
                        </div>
                    </div>
                </el-tab-pane>

                <el-tab-pane
                    label="用户分享"
                    name="three"
                >
                    <div class="container">
                        <div class="contarea-panel">
                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">专业：</dd>
                                <dd
                                    :class="{'active':professionIndex==0}"
                                    @click="toggle('profession',0)"
                                >全部</dd>
                                <dd
                                    @click="toggle('profession',item.id)"
                                    v-for="(item,index) in cateList"
                                    :key="index"
                                    :class="{'active':professionIndex==item.id}"
                                >{{item.title}}</dd>
                            </dl>

                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">排序：</dd>
                                <dd
                                    :class="{'active':sortIndex==1}"
                                    @click="toggle('sort',1)"
                                >最新</dd>
                                <dd
                                    :class="{'active':sortIndex==2}"
                                    @click="toggle('sort',2)"
                                >最热</dd>
                            </dl>

                            <article-list
                                ref="articleComp3"
                                :cate="cate"
                                :profession="profession"
                                :sort="sort"
                                :type="type"
                                :keyword="keyword"
                                :hot="hot"
                            ></article-list>
                        </div>
                        <div class="sidebar-panel">
                            <sidebar-panel
                                :news="news"
                                :dataShare="dataShare"
                            ></sidebar-panel>
                        </div>
                    </div>
                </el-tab-pane>

                <el-tab-pane
                    label="已收藏"
                    name="four"
                >
                    <div class="container">
                        <div class="contarea-panel">
                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">分类：</dd>
                                <dd
                                    :class="{'active':cateIndex==0}"
                                    @click="toggle('cate',0)"
                                >全部</dd>
                                <dd
                                    :class="{'active':cateIndex==1}"
                                    @click="toggle('cate',1)"
                                >行业新闻</dd>
                                <dd
                                    :class="{'active':cateIndex==2}"
                                    @click="toggle('cate',2)"
                                >政策文件</dd>
                                <dd
                                    :class="{'active':cateIndex==3}"
                                    @click="toggle('cate',3)"
                                >收费标准</dd>
                                <dd
                                    :class="{'active':cateIndex==4}"
                                    @click="toggle('cate',4)"
                                >标准规范</dd>
                                <dd
                                    :class="{'active':cateIndex==5}"
                                    @click="toggle('cate',5)"
                                >其他资料</dd>
                            </dl>

                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">专业：</dd>
                                <dd
                                    :class="{'active':professionIndex==0}"
                                    @click="toggle('profession',0)"
                                >全部</dd>
                                <dd
                                    @click="toggle('profession',item.id)"
                                    v-for="(item,index) in cateList"
                                    :key="index"
                                    :class="{'active':professionIndex==item.id}"
                                >{{item.title}}</dd>
                            </dl>

                            <dl class="col-panel">
                                <dd style="margin:0px;padding:0px;height:40px;line-height:40px;">排序：</dd>
                                <dd
                                    :class="{'active':sortIndex==1}"
                                    @click="toggle('sort',1)"
                                >最新</dd>
                                <dd
                                    :class="{'active':sortIndex==2}"
                                    @click="toggle('sort',2)"
                                >最热</dd>
                            </dl>

                            <article-list
                                ref="articleComp4"
                                :cate="cate"
                                :profession="profession"
                                :sort="sort"
                                :type="type"
                                :keyword="keyword"
                                :hot="hot"
                            ></article-list>
                        </div>
                        <div class="sidebar-panel">
                            <sidebar-panel
                                :news="news"
                                :dataShare="dataShare"
                            ></sidebar-panel>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <global-footer></global-footer>
    </div>
</template>

<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import ArticleList from "@/components/article-list/ArticleList.vue";
import SidebarPanel from "@/components/common/SidebarPanel.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";

export default {
    name: "articleIndex",
    metaInfo: {
        title: "资源文章分享列表 - 询龙网",
        meta: [
            {
                name: "keywords",
                content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
            }, {
                name: "description",
                content: "在这里查看下载最新的资源和文章分享信息"
            }
        ]
    },
    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        ArticleList, //文章列表
        SidebarPanel, //右边公共模块
        GlobalFooter //公共底部
    },
    created() {
        this.getCate();//默认加载分类
        this.urlRoute(); //默认加载url传过来的分类和文章列表
        this.loadHotData();//获取热门新闻
        this.loadAuthorsData();//获取热心作者列表
    },
    data() {
        return {
            activeName: "one", //tab选项卡
            cateList: [], //文章分类list
            news: '',
            dataShare: '',

            cateIndex: 0,
            professionIndex: 0,
            sortIndex: 1,

            hot: 0,//热门
            type: 0,//tab索引
            sort: 1, //文章组件 - 排序（1.最新发布，2.最热资源）
            cate: 0, //文章组件 - 分类（1.行业新闻；2.政策文件；3.收费标准；4.标准规范；5.其他资料；6.官网资料）
            profession: 0,//文章组件 - 专业（1.项目测绘；2.项目评估；3.工程勘察；4.工程设计；5.工程造价；6.工程监理；7.财务会计；8.税务服务；9.工程法务；10.其他资料）

            keyword: '',//搜索
        };
    },
    methods: {
        //tab切换事件
        tabChange(tab) {
            //初始化
            this.cate = this.cateIndex = this.profession = this.professionIndex = this.hot = 0;
            this.sort = this.sortIndex = 1;
            this.keyword = '';

            //如果是【用户分享】
            if (tab.index == 2) {
                this.cate = 5;//cate选为其他资料
            }
            this.type = tab.index;

            //加载文章列表
            this.loadData();
        },

        //点击分类
        toggle(name, index) {
            let _this = this;
            if (name == 'cate') {
                _this.cate = _this.cateIndex = index;//分类
            } else if (name == 'profession') {
                _this.profession = _this.professionIndex = index;//专业
            } else if (name == 'sort') {
                _this.sort = _this.sortIndex = index;//排序
            } else {
                return;
            }
            //加载文章列表
            _this.loadData();
        },

        //搜素
        search() {
            //加载文章列表
            this.loadData();
        },

        //加载数据
        loadData() {
            let _this = this;
            switch (parseInt(this.type) + 1) {
                case 1:
                    setTimeout(function () {
                        _this.$refs.articleComp1.loadData();
                    }, 30);
                    break;
                case 2:
                    setTimeout(function () {
                        _this.$refs.articleComp2.loadData();
                    }, 30);
                    break;
                case 3:
                    setTimeout(function () {
                        _this.$refs.articleComp3.loadData();
                    }, 30);
                    break;
                case 4:
                    setTimeout(function () {
                        _this.$refs.articleComp4.loadData();
                    }, 30);
                    break;
            }
        },

        //获取文章分类
        getCate() {
            let _this = this;
            _this.post("/article/index/cate", {}, function (res) {
                if (res.code == 200) {
                    _this.cateList = res.data;
                }
            });
        },

        //加载热门文章列表数据
        loadHotData() {
            let _this = this;
            this.post("/article/index/hot", {}, function (res) {
                if (res.code == 200) {
                    _this.news = res.data;
                }
            });
        },

        //加载人心分享者列表数据
        loadAuthorsData() {
            let _this = this;
            _this.post("/article/index/authors", {}, function (res) {
                if (res.code == 200) {
                    _this.dataShare = res.data;
                }
            });
        },

        //路由访问
        urlRoute() {
            let _this = this;

            //初始化
            _this.type = 0;
            _this.activeName = 'one';

            _this.cate = _this.cateIndex = 0;
            _this.profession = _this.professionIndex = 0;
            _this.sort = _this.sortIndex = 1;
            _this.keyword = '';

            //热门
            if (_this.$route.query.hot) {
                _this.hot = parseInt(_this.$route.query.hot);
            }

            //分类
            if (_this.$route.query.cate) {
                _this.cate = _this.cateIndex = parseInt(_this.$route.query.cate);
            }

            //专业
            if (_this.$route.query.profession) {
                _this.profession = _this.professionIndex = parseInt(_this.$route.query.profession);
            }

            //排序
            if (_this.$route.query.sort) {
                _this.sort = _this.sortIndex = parseInt(_this.$route.query.sort);
            }

            //type
            if (_this.$route.query.type) {
                //切换tab
                switch (_this.$route.query.type) {
                    case "0":
                        _this.activeName = 'one';
                        break;
                    case "1":
                        _this.activeName = 'two';
                        break;
                    case "2":
                        _this.activeName = 'three';
                        break;
                    case "3":
                        _this.activeName = 'four';
                        break;
                }
                _this.type = _this.$route.query.type;
            }

            //加载文章列表
            _this.loadData();
        }
    },

    watch: {
        $route() {
            this.urlRoute();
        }
    }
};
</script>
<style lang="less">
@import "../../styles/subscribe.less";
.article-items span + span {
    margin-left: 4px;
}
.article-items .bd {
    margin: 0;
    margin-top: 6px;
}
.article-items .ftr {
    margin-top: 6px;
}
.article-items .tag {
    width: 60%;
}
.article-items .download {
    width: 40%;
}
.articleBox {
    width: 1200px;
    margin-left: -600px;
    left: 50%;
    position: relative;
    .searchBox {
        position: absolute;
        right: 0;
        top: 16px;
        width: 300px;
        height: 40px;
        z-index: 999;
        .el-input {
            width: 70%;
            float: right;
            .el-input__inner {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border-right: none;
            }
        }
        .el-button {
            float: right;
            width: 80px;
            height: 36px;
            line-height: 36px;
            border: none;
            border-radius: 0;
            background: #587ddc;
            font-size: 14px;
            color: white;
            text-align: center;
            padding: 0px;
            margin: 0px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}
</style>


