<template>
    <div v-loading="loading">
        <ul
            class="article-list"
            v-if="artList.length>0"
        >
            <router-link
                tag="li"
                :to="{path:'/article/detail/'+item.id}"
                v-for="(item,index) in artList"
                :key="index"
                class="item"
            >
                <div
                    class="left"
                    v-if="item.img"
                >
                    <img
                        class="pic"
                        :src="util.reImg(item.img)"
                    />
                </div>

                <div class="center">
                    <h2 class="title">
                        <i :class="{'jk-icon-huo':item.is_hot === 1}"></i>
                        <a
                            class="text"
                            href="jacascript:void(0)"
                        >{{item.title}}</a>
                    </h2>

                    <div class="tag">
                        <span
                            class="jinghua"
                            v-text="item.cate_id.value"
                        ></span>
                    </div>

                    <div class="view">
                        <span>
                            <i class="icon jk-icon-liulanliang"></i>
                            <span>{{item.view_count}}</span>
                        </span>
                        <span>
                            <i class="icon jk-icon-dianzanshu"></i>
                            {{item.like_count}}
                        </span>
                    </div>
                </div>

                <div class="right">
                    <div class="tag_icon">
                        <span
                            class="guangfang"
                            v-if="item.is_official"
                        >
                            <img
                                class="icon"
                                :src="$img.guanfang"
                            />
                            <span class="text">官方</span>
                        </span>
                    </div>

                    <div
                        class="download"
                        v-if="item.annex_count>0"
                    >
                        <div v-if="item.download_point_count==0 && item.download_price==0">
                            <em class="orange">免费下载</em>
                        </div>

                        <div v-if="item.download_point_count>0 || item.download_price>0">
                            <span v-if="item.download_point_count>0 && item.download_price>0">
                                下载所需:
                                <em class="orange">点券{{item.download_point_count}}点或(费用{{item.download_price}}元)</em>
                            </span>
                            <span v-else>
                                <span v-if="item.download_point_count>0">
                                    下载所需点券:
                                    <em class="orange">{{item.download_point_count}}点</em>
                                </span>

                                <span v-if="item.download_price>0">
                                    下载所需费用:
                                    <em>{{item.download_price}}元</em>
                                </span>
                            </span>
                        </div>

                        <div>
                            下载次数:
                            <em class="orange">{{item.download_count}}次</em>
                        </div>
                    </div>

                    <div class="time">
                        <span class="date">{{item.create_time}}</span>
                    </div>
                </div>
            </router-link>
        </ul>

        <div
            v-else
            class="emptyData"
        >没有更多相关数据~~</div>

        <pagination
            v-if="artList.length >0"
            :pageSize="pageSize"
            :currentPage="currentPage"
            :pageTotal="pageTotal"
            @current-change="newPage"
        ></pagination>
    </div>
</template>

<script>
import Pagination from "@/components/common/Pagination.vue";
export default {
    name: "ArticleLists",
    components: {
        Pagination
    },
    props: ["cate", "profession", "sort", "type", "keyword", "hot"],
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            pageTotal: 0,
            artList: [],
            loading: false
        };
    },
    methods: {
        newPage(page) {
            this.currentPage = page;
            this.getList();
        },

        //资源分享 - 全部
        loadData() {
            this.currentPage = 1;//初始化分页
            this.getList();//加载数据
        },
        
        //获取文章列表数据
        getList() {
            let _this = this;
            _this.artList = [];//清空列表

            let url = "";
            if (_this.type == 3) {
                url = "/article/article/collect_list"; //已收藏
            } else {
                url = "/article/index/lists";//未收藏
            }

            //请求文章数据
            _this.loading = true;//加载loading
            _this.post(
                url, {
                cate: _this.cate,
                type: _this.type,
                profession: _this.profession,
                sort: _this.sort,
                hot: _this.hot,
                keyword: _this.keyword,
                page: _this.currentPage,
                limit: _this.pageSize
            }, function (res) {
                _this.loading = false;
                if (res.code == 200) {
                    _this.artList = res.data.list;
                    _this.pageTotal = res.data.count;
                }
            }, done => { }, error => { _this.loading = false; });
        }
    }
};
</script>

<style lang="less">
//文章公共样式
.article-list {
    width: 100%;

    .item {
        height: 140px;
        padding: 0;
        border-bottom: 1px solid #ddd;

        .left {
            height: 140px;
            width: 100px;
            float: left;
            padding-top: 20px;
            margin-right: 20px;
            .pic {
                width: 100px;
                height: 100px;
                border-radius: 3px;
            }
        }

        .center {
            height: 140px;
            width: 610px;
            float: left;
            .title {
                padding: 0;
                margin: 0;
                height: 30px;
                line-height: 30px;
                margin-top: 14px;
                .jk-icon-huo {
                    float: left;
                    width: 17px;
                    color: #ffaa24;
                    margin-right: 3px;
                }
                .text {
                    float: left;
                    color: #333;
                    font-weight: normal;
                    font-size: 16px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 590px;
                    display: inline-block;
                    &:hover {
                        color: #587ddc;
                    }
                }
            }

            .tag {
                margin-top: 18px;
                font-size: 12px;
                .jinghua {
                    background: #ffaa24;
                    color: #fff;
                    padding: 2px 4px;
                    border-radius: 4px;
                }
            }

            .view {
                margin-top: 18px;
                .icon {
                    font-size: 14px;
                    color: #999;
                }
                span {
                    font-size: 14px;
                    color: #999;
                }
            }
        }

        .right {
            float: right;
            height: 140px;
            width: 180px;
            position: relative;
            font-size: 12px;
            color: #999;

            .tag_icon {
                margin-top: 20px;
                .guangfang {
                    display: inline-block;
                    height: 22px;
                    padding: 0 10px;
                    line-height: 22px;
                    font-size: 12px;
                    color: #333;
                    float: right;
                    background: linear-gradient(135deg, #ffedc9, #bba281 99%);
                    vertical-align: middle;
                    .icon {
                        margin-top: 6px;
                        float: left;
                    }
                    .text {
                        float: left;
                        margin-left: 4px;
                    }
                }
            }

            .download {
                position: absolute;
                right: 0;
                top: 50px;
                width: 100%;
                text-align: right;
                div {
                    margin-bottom: 4px;
                }
            }

            .time {
                position: absolute;
                right: 0;
                top: 100px;
                text-align: right;
                width: 100%;
                color: #999;
                span {
                    width: 100%;
                    text-align: right;
                    color: #999;
                }
            }
        }
    }
}
</style>
